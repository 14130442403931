import { BillableMetricsCode } from '@prisma/client';
import Image from 'next/image';
import Link from 'next/link';
import { signOut } from 'next-auth/react';
import { useEffect } from 'react';

import { useToggle, useViewportWidth } from '@/hooks';
import { api } from '@/levity/api';
import { FeatureFlag, useFeatureFlags } from '@/levity/feature-flags';
import { Icons } from '@/ui-library';
import { ROUTER_EXCEPTIONS } from '@/utils/api';
import { cn } from '@/utils/styles';
import { URLs } from '@/utils/urls';

import { NavItem, ProfileDropdown, UsageIndicator } from './components';
import type { SidebarProps } from './types';

export const Sidebar: React.FC<SidebarProps> = (props) => {
  const { mainNavItems, userMenuItems, user } = props;
  const { data: activePlanName } = api.PlansRouter.getActivePlanName.useQuery();

  const { data: planLimitsAndStatus, error } = api.PlansRouter.getPlanLimitsAndStatus.useQuery({
    billableMetricCode: BillableMetricsCode.actions_v1,
  });
  const { flagValue: isActivityPageEnabled } = useFeatureFlags(FeatureFlag.FLOW_RUNS);
  const filteredMainNavItems = mainNavItems.filter((item) => item.name !== 'Activity' || isActivityPageEnabled);
  const viewportWidth = useViewportWidth();

  const { isOn: isCompact, toggle: toggleIsCompact } = useToggle(viewportWidth < 768);

  useEffect(() => {
    if (error && error.message === ROUTER_EXCEPTIONS.ForceUserSignOut.message) {
      signOut();
    }
  }, [error]);
  return (
    <nav
      className={cn(
        'group transition-all ease-in-out flex flex-col z-[50] h-screen bg-Sidebar border-r border-NeutralLightMedium pb-4 pt-8 text-HighlightDarkest',
        'fixed left-0 top-0', // Note: positions sidebar on top of main content on mobile
        'md:relative', // Note: on desktop (and above), revert to relative positioning to bring it back to normal flow
        isCompact ? 'w-14 px-3' : 'w-60 px-4 ',
      )}
      data-testid='sidebar'>
      <div className={cn('min-h-[24px] flex items-center justify-between', isCompact && 'mx-auto')}>
        {!isCompact && (
          <Link href={URLs.Home().pathname}>
            <Image src='/imgs/levity-logo.svg' width={75} height={23} alt='Levity logo' className='ml-2' />
          </Link>
        )}
        {isCompact ? (
          <button
            className='text-NeutralDarkLightest hover:text-HighlightDarkest'
            onClick={() => toggleIsCompact()}
            title='Expand sidebar'>
            <Icons.BurgerMenu />
          </button>
        ) : (
          <button
            className='opacity-0 group-hover:opacity-100 group-hover:translate-x-0 transition-opacity flex text-NeutralDarkLightest hover:text-HighlightDarkest'
            title='Compact sidebar'
            onClick={() => toggleIsCompact()}
            data-testid='sidebar-toggle'>
            <Icons.Compact />
          </button>
        )}
      </div>
      <div className='h-10 min-h-[40px]' />
      <div className='flex flex-col gap-2'>
        {filteredMainNavItems.map((item) => (
          <NavItem key={item.name} item={item} isCompact={isCompact} />
        ))}
      </div>

      <div className='h-full' />

      {!isCompact && planLimitsAndStatus && (
        <UsageIndicator
          planName={activePlanName}
          actionsAvailable={planLimitsAndStatus.consumptionLimits.limit}
          actionsUsed={planLimitsAndStatus.consumptionLimits.used}
        />
      )}
      <div className='h-8' />
      <ProfileDropdown user={user} userNavigation={userMenuItems} isCompact={isCompact} />
    </nav>
  );
};
