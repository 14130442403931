export const flatten = (json: object) => {
  const flatJson: Record<string, unknown> = {};
  for (const [key, value] of Object.entries(json)) {
    if (Array.isArray(value) || typeof value !== 'object') {
      flatJson[key] = value;
      continue;
    }
    const flatNestedJson = flatten(value);
    for (const [nestedKey, nestedValue] of Object.entries(flatNestedJson)) {
      flatJson[`${key}.${nestedKey}`] = nestedValue;
    }
  }
  return flatJson;
};

export const deepParseJson = (obj: string | object): string | object => {
  if (typeof obj === 'string') {
    try {
      return deepParseJson(JSON.parse(obj));
    } catch {
      return obj;
    }
  }

  if (Array.isArray(obj)) {
    return obj.map(deepParseJson);
  }

  if (obj && typeof obj === 'object') {
    return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, deepParseJson(v)]));
  }

  return obj;
};

export const formatJsonData = (data: string | object): string => {
  try {
    const parsed = typeof data === 'string' ? JSON.parse(data) : data;
    return JSON.stringify(deepParseJson(parsed), null, 2);
  } catch {
    return JSON.stringify(data);
  }
};
