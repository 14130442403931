import { flexRender, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import React from 'react';

import { LazyLoader } from '@/ui-library/lazy-loader/lazy-loader.component';
import { cn } from '@/utils/styles';

import { getDataTableSizeStyles } from './components/data-table.core';
import { DataTableProps, FlattenedItem } from './components/data-table.types';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './components/table';

export function DataTable<TData, TValue, TGroupValue>({
  columns,
  data,
  hideHeader = false,
  size = 'md',
  className,
  cellClassName,
  headerClassName,
  growingColumnId,
  grouping,
  rowHeight = 40,
  onLoadMore,
  isLoading,
  hasMoreData,
  dataTestId,
}: DataTableProps<TData, TValue, TGroupValue>) {
  const table = useReactTable<TData>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const flattenedItems = React.useMemo((): FlattenedItem<TData, TGroupValue>[] => {
    if (!grouping) return table.getRowModel().rows.map((row) => ({ type: 'row', row }));

    const groupedRows = table.getRowModel().rows.reduce(
      (groups, row) => {
        const key = String(row.original[grouping.key as keyof TData] ?? '');
        return {
          ...groups,
          [key]: [...(groups[key] || []), row],
        };
      },
      {} as Record<string, Row<TData>[]>,
    );

    return Object.entries(groupedRows).flatMap(([key, rows]) => [
      { type: 'group' as const, key, value: key as TGroupValue },
      ...rows.map((row) => ({ type: 'row' as const, row })),
    ]);
  }, [table, grouping]);

  const parentRef = React.useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: flattenedItems.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => rowHeight,
    overscan: 10,
  });

  return (
    <div
      className={cn('rounded-md border max-h-screen flex flex-col overflow-hidden', className)}
      data-testid={dataTestId ?? 'data-table'}>
      <Table className='flex-1 flex flex-col min-h-0'>
        {!hideHeader && (
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id} className={cn(getDataTableSizeStyles(size).header, headerClassName)}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
        )}
        <TableBody className='flex-1 min-h-0'>
          <div
            ref={parentRef}
            className='h-full'
            style={{
              overflow: 'auto',
              minHeight: 0,
            }}>
            <div
              style={{
                height: `${virtualizer.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
              }}>
              {virtualizer.getVirtualItems().map((virtualRow) => {
                const item = flattenedItems[virtualRow.index];

                if (item?.type === 'group') {
                  return (
                    <div
                      key={item.key}
                      className='bg-gray-50 px-4 font-medium border-b flex items-center text-sm text-gray-600'
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: `${rowHeight}px`,
                        transform: `translateY(${virtualRow.start}px)`,
                        left: 0,
                        top: 0,
                      }}>
                      {grouping?.renderGroupHeader?.(item.value) ?? String(item.value)}
                    </div>
                  );
                }

                if (!item || item.type !== 'row') throw new Error('Invalid item type');
                const row = item.row;
                return (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                    className={getDataTableSizeStyles(size).row}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      transform: `translateY(${virtualRow.start}px)`,
                    }}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className={cn(
                          getDataTableSizeStyles(size).cell,
                          cell.column.id === growingColumnId ? 'w-full' : 'w-fit',
                          cellClassName,
                        )}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </div>
            {onLoadMore && (
              <LazyLoader loadMoreData={onLoadMore} isLoading={isLoading || false} hasMoreData={hasMoreData || false}>
                <div style={{ height: '1px' }} />
              </LazyLoader>
            )}
          </div>
        </TableBody>
      </Table>
    </div>
  );
}
