import { CopyTagTypes, type OperationDefinition } from '@/levity/flows/types';

type GWOperationKeys = 'GetCurrentStatusByOrderId' | 'GetCurrentStatusByParams';

export const gwOperationsDefinition: Record<GWOperationKeys, OperationDefinition> = {
  GetCurrentStatusByOrderId: {
    id: 'gw-get-current-status-by-order-id',
    name: 'Get current status of order by ID',
    icon: 'gw',
    description: 'Track & Trace: Get current status of order by ID',
    hasDynamicInputs: true,
    hideDefaultDynamicInput: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Get current status of order by ID',
        tooltips: {
          description: 'Track & Trace: Get current status of order by ID',
        },
      },
      interactiveParameters: {
        label: 'Get current status of order by ID',
        title: 'Get current status of order by ID',
        tooltips: {
          description: 'Set your order ID here to get the latest status of an order.',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: [],
        action: `Get current status of order by ID`,
      },
    },
  },
  GetCurrentStatusByParams: {
    id: 'gw-get-current-status-by-params',
    name: 'Get current status of orders by parameters',
    description: 'Track & Trace: Get current status of orders by parameters',
    icon: 'gw',
    hasDynamicInputs: true,
    hideDefaultDynamicInput: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Get current status of orders by parameters',
        tooltips: {
          description: 'Set the information needed to get the latest status of an order.',
        },
      },
      interactiveParameters: {
        label: 'Get current status of orders by parameters',
        title: 'Get current status of orders by parameters',
        tooltips: {
          description: 'Track & Trace: Get current status of orders by parameters',
        },
      },
      actionDefinition: {
        elementTags: [],
        action: `Get current status of orders by parameters`,
      },
    },
  },
};
