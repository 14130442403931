import React from 'react';

import { cn } from '@/utils/styles';

import { Tooltip, TooltipPosition } from '../tooltip';
import { baseStyles, collapsedStyles, getVariantStyles, iconContainerStyles, iconStyles } from './pill.core';
import { type PillProps, PillStatusVariant } from './pill.types';

export const Pill = ({ text, icon, variant = PillStatusVariant.Info, collapsed = false, tooltip }: PillProps) => {
  const content = (
    <div className={cn(baseStyles, getVariantStyles(variant), collapsed && collapsedStyles)}>
      {icon && <span className={cn(iconContainerStyles, iconStyles)}>{icon}</span>}
      {!collapsed && <span>{text}</span>}
    </div>
  );

  return tooltip ? (
    <Tooltip content={{ description: tooltip.content }} position={tooltip.position || TooltipPosition.BottomCenter}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};
