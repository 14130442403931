import React from 'react';
import CircleLoader from 'react-spinners/ClipLoader';

import { Button, Dialog, Icons } from '@/ui-library';
import { formatJsonData } from '@/utils/json';
import { cn } from '@/utils/styles';

import {
  TestDialogDataProps,
  TestDialogErrorPanelProps,
  TestDialogFooterProps,
  TestDialogLoadingProps,
  TestDialogProps,
  TestDialogSuccessPanelProps,
  TestDialogWrapperProps,
} from './test-dialog.types';

export const TestDialog = (props: TestDialogProps) => {
  const { isOpen, close, copy, state = 'loading', children, dissableChildrenPadding = true, customFooter } = props;

  return (
    <TestDialog.Wrapper title={copy.title} isOpen={isOpen} close={close} dissablePadding={dissableChildrenPadding}>
      {state === 'loading' && <TestDialog.Loading title={copy.states?.loading?.title} />}
      {state === 'success' && (
        <div className='flex flex-col gap-5 w-full min-h-[380px] pt-5'>
          <div className='flex-grow'>
            <TestDialog.SuccessPanel
              title={copy.states?.success?.title}
              subtitle={copy.states?.success?.subtitle}
              className={dissableChildrenPadding ? 'mx-12' : 'm-0'}
            />
            {children}
          </div>

          <TestDialog.Footer close={close} className={dissableChildrenPadding ? 'mx-12 mb-12' : 'm-0'} />
        </div>
      )}
      {state === 'error' && (
        <div className='flex flex-col gap-5 w-full min-h-[380px] pt-5'>
          <div className='flex-grow'>
            <TestDialog.ErrorPanel
              title={copy.states?.error?.title}
              subtitle={copy.states?.error?.subtitle}
              className={dissableChildrenPadding ? 'mx-12' : 'm-0'}
            />
            {children}
          </div>

          <TestDialog.Footer
            close={close}
            customButtons={customFooter}
            className={dissableChildrenPadding ? 'mx-12 mb-12' : 'm-0'}
          />
        </div>
      )}
    </TestDialog.Wrapper>
  );
};

const TestDialogWrapper = (props: TestDialogWrapperProps) => {
  const { title, isOpen, close, children, dissablePadding = false } = props;

  return (
    <Dialog
      title={title}
      isOpen={isOpen}
      onClose={close}
      dissablePadding={dissablePadding}
      width='xl:min-w-4xl xl:max-w-4xl lg:min-w-3xl lg:max-w-3xl md:min-w-2xl md:max-w-2xl max-w-md'>
      {children}
    </Dialog>
  );
};

const TestDialogLoading = (props: TestDialogLoadingProps) => {
  const { title, className } = props;

  return (
    <div className={cn('flex flex-col items-center justify-center gap-6 w-full min-h-[380px]', className)}>
      <CircleLoader color='#5557F6' size={38} cssOverride={{ borderWidth: '4px' }} />
      {title && <p className=' text-NeutralDarkMedium text-[13px] font-normal'>{title}</p>}
    </div>
  );
};

const TestDialogSuccessPanel = (props: TestDialogSuccessPanelProps) => {
  const { title, subtitle, className } = props;

  return (
    <div className={cn('flex flex-row items-center rounded-2xl p-4 gap-4 bg-SupportSuccessLight', className)}>
      <div className='flex items-center justify-center min-h-full h-full'>
        <Icons.CheckCircle className='w-6 h-6 text-SupportSuccess' />
      </div>
      <div className='flex flex-col gap-1'>
        {title && <span className='text-NeutralDarkDarkest text-[13px] font-bold text-left'>{title}</span>}
        {subtitle && <span className='text-NeutralDarkMedium text-[13px] font-normal text-left'>{subtitle}</span>}
      </div>
    </div>
  );
};

const TestDialogErrorPanel = (props: TestDialogErrorPanelProps) => {
  const { title, subtitle, className } = props;

  return (
    <div className={cn('flex flex-row items-center rounded-2xl p-4 gap-4 bg-SupportErrorLight', className)}>
      <div className='flex items-center justify-center min-h-full h-full'>
        <Icons.ExclamationCircle className='w-6 h-6 text-SupportErrorMedium' />
      </div>
      <div className='flex flex-col gap-1'>
        {title && <span className='text-NeutralDarkDarkest text-[13px] font-bold text-left'>{title}</span>}
        {subtitle && <span className='text-NeutralDarkMedium text-[13px] font-normal text-left'>{subtitle}</span>}
      </div>
    </div>
  );
};

const TestDialogData = (props: TestDialogDataProps) => {
  const { data, className } = props;
  return (
    <div
      className={cn(
        'bg-NeutralLightLight rounded-2xl p-4 flex items-start text-left overflow-scroll max-h-[348px] grow mt-4',
        className,
      )}>
      <pre id='json' className='text-[13px] whitespace-pre'>
        {formatJsonData(data)}
      </pre>
    </div>
  );
};

const TestDialogFooter = (props: TestDialogFooterProps) => {
  const { close, buttonText = 'Done', className, customButtons } = props;

  return (
    <div className={cn('flex items-center justify-end gap-3', className)}>
      {customButtons ?? <Button text={buttonText} onClick={close} className='px-6' />}
    </div>
  );
};

TestDialog.Wrapper = TestDialogWrapper;
TestDialog.Loading = TestDialogLoading;
TestDialog.SuccessPanel = TestDialogSuccessPanel;
TestDialog.ErrorPanel = TestDialogErrorPanel;
TestDialog.Data = TestDialogData;
TestDialog.Footer = TestDialogFooter;
