import { PillAiBlockVariant, PillStatusVariant, PillVariant } from './pill.types';

export const getVariantStyles = (variant: PillVariant = PillStatusVariant.Info) => {
  const statusStyles = {
    [PillStatusVariant.Success]: 'bg-SupportSuccessLight text-SupportSuccessDark',
    [PillStatusVariant.Warning]: 'bg-SupportWarningLight text-SupportWarningDark',
    [PillStatusVariant.Error]: 'bg-SupportErrorLight text-SupportErrorDark',
    [PillStatusVariant.Info]: 'bg-HighlightLightest text-HighlightDarkest',
  };

  const aiBlockStyles = {
    [PillAiBlockVariant.Generate]: 'bg-[#EFEEFF] text-[#5557F6]',
    [PillAiBlockVariant.Classify]: 'bg-[#D7F4CA] text-[#6CAC62]',
    [PillAiBlockVariant.Extract]: 'bg-[#FFF2CB] text-[#E7852B]',
    [PillAiBlockVariant.Summarize]: 'bg-[#F6E2FF] text-[#E72BBD]',
    [PillAiBlockVariant.Custom]: 'bg-[#EFEEFF] text-[#5557F6]',
  };

  const styles = { ...statusStyles, ...aiBlockStyles };

  return styles[variant];
};

export const baseStyles = 'flex w-fit items-center gap-2 rounded-full px-3 py-1.5 text-[10px] font-semibold uppercase';
export const collapsedStyles = 'p-2 aspect-square';
export const iconStyles = 'h-3.5 w-3.5';
export const iconContainerStyles = 'flex items-center justify-center';
