import { TooltipPosition } from '../tooltip';

export enum PillStatusVariant {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}

export enum PillAiBlockVariant {
  Generate = 'generate',
  Classify = 'classify',
  Extract = 'extract',
  Summarize = 'summarize',
  Custom = 'custom',
}

export type PillVariant = PillStatusVariant | PillAiBlockVariant;

export interface PillProps {
  text: string;
  icon?: React.ReactNode;
  variant?: PillVariant;
  collapsed?: boolean;
  tooltip?: {
    content: string;
    position?: TooltipPosition;
  };
}
