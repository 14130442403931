import type { ParsedUrlQuery } from 'querystring';

import { getSampleBlock } from './sample-blocks';

export const URLs = {
  Home: () => ({ pathname: '/' }),
  Activity: (flowId?: string) => ({
    pathname: '/activity',
    search: flowId ? `?flowId=${flowId}` : '',
  }),
  Login: () => ({ pathname: '/account/login' }),
  Logout: () => ({ pathname: '/account/logout' }),
  WorkspaceInvitation: () => ({ pathname: '/account/workspace-invitation' }),
  ManageAccount: () => ({ pathname: '/account/users' }),
  AuthError: () => ({ pathname: '/account/error' }),
  Signup: () => ({ pathname: '/account/signup' }),
  Flows_Overview: () => ({
    pathname: '/flows',
  }),
  Flows_Detail: (id: string) => ({
    pathname: `/flows/${id}/manage`,
  }),
  AiBlocks_List: () => ({
    pathname: '/ai-blocks',
  }),
  AiBlock_Detail: (aiBlockId: string, aiBlockName?: string) => {
    const params = new URLSearchParams();

    if (aiBlockName) {
      const sampleAiBlock = getSampleBlock(aiBlockName);
      if (sampleAiBlock) {
        params.append('initialTestInput', sampleAiBlock.initialTestInput);
        if (sampleAiBlock.initialContext) {
          params.append('initialContext', sampleAiBlock.initialContext);
        }
      }
    }

    const query = params.toString() ? `?${params.toString()}` : '';

    return {
      pathname: `/details/${aiBlockId}/build`,
      search: query,
    };
  },
  AiBlock_Connect: (aiBlockId: string) => ({
    pathname: `/details/${aiBlockId}/connect`,
  }),
  AiBlock_Memory: (aiBlockId: string) => ({
    pathname: `/details/${aiBlockId}/memory`,
  }),
  Settings_Overview: () => ({
    pathname: `/settings`,
  }),
  Plans: () => ({
    pathname: `/plans`,
  }),
  Terms: () => ({
    pathname: `https://levity.ai/terms`,
  }),
  PrivacyPolicy: () => ({
    pathname: `https://levity.ai/privacy-policy`,
  }),
};

export const buildQueryString = (query: ParsedUrlQuery): string => {
  const params = new URLSearchParams(query as Record<string, string>);
  const queryString = params.toString();

  return queryString ? `?${queryString}` : '';
};
