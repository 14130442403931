import React, { useCallback, useEffect, useRef } from 'react';

import { type LazyLoaderProps } from './lazy-loader.types';

export const LazyLoader = ({ children, loadMoreData, isLoading, hasMoreData, threshold = 100 }: LazyLoaderProps) => {
  const loaderRef = useRef<HTMLDivElement>(null);

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[], _observer: IntersectionObserver) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isLoading && hasMoreData) {
          loadMoreData();
        }
      });
    },
    [isLoading, hasMoreData, loadMoreData],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: `${threshold}px`,
      threshold: 0,
    });

    const currentLoaderRef = loaderRef.current;

    if (currentLoaderRef) {
      observer.observe(currentLoaderRef);
    }

    return () => {
      if (currentLoaderRef) {
        observer.unobserve(currentLoaderRef);
      }
    };
  }, [handleIntersection, threshold]);

  return (
    <div>
      {children}
      {hasMoreData && <div ref={loaderRef} />}
    </div>
  );
};
