import React from 'react';

import { IconProps } from '../icons.types';

export const CheckIcon: React.FC<IconProps> = (props) => (
  <svg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M9.39274 1.09479C9.03593 0.710474 8.44441 0.704493 8.08056 1.08251L3.98318 5.33948L1.92051 3.19648C1.55666 2.81846 0.965144 2.82444 0.608332 3.20875C0.260383 3.58351 0.265186 4.17954 0.619934 4.54811L3.98318 8.04234L9.38114 2.43414C9.73589 2.06558 9.74069 1.46955 9.39274 1.09479Z'
      fill='#23AD73'
    />
  </svg>
);
