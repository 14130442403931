import React, { useEffect, useState } from 'react';
import CircleLoader from 'react-spinners/ClipLoader';

import {
  FrontReplyToMessage,
  GmailCategorizeMessage,
  GmailCreateDraft,
  GmailForwardEmail,
  GSheetsAddNewRow,
  GSheetsUpdateRow,
  HubspotUpdateTicketProperty,
  LoggerBlock,
  OutlookCategorizeMessage,
  OutlookCopyToFolder,
  OutlookDraftReply,
  OutlookForwardEmail,
  OutlookGetAllMessages,
  OutlookMoveToFolder,
  OutlookReplyEmail,
  OutlookSendNewEmail,
} from '@/components/test-flows-success-screens';
import { GetCurrentStatusByOrderId } from '@/components/test-flows-success-screens/gw/gw-get-status-by-id';
import { GetCurrentStatusByParams } from '@/components/test-flows-success-screens/gw/gw-get-status-by-params';
import { NetworkHttpRequest } from '@/components/test-flows-success-screens/network-http-request';
import { SlackSendMessage } from '@/components/test-flows-success-screens/slack-send-message';
import { YoutubeDeleteComment } from '@/components/test-flows-success-screens/youtube-delete-comment';
import { YoutubeHoldCommmentForReview } from '@/components/test-flows-success-screens/youtube-hold-comment-for-review';
import { YoutubePostComment } from '@/components/test-flows-success-screens/youtube-post-comment';
import { CopyType } from '@/levity/flows/copy';
import { gsheetsOperationsDefinition } from '@/levity/integrations/registry/apps/gsheets.definitions';
import { definition as slackOperationsDefinition } from '@/levity/integrations/registry/apps/slack/slack.definition';
import { hubspotOperationsDefinition } from '@/levity/integrations/registry/customer-service/providers/hubspot.definition';
import { developmentOperationsDefinition } from '@/levity/integrations/registry/development/development.definition';
import { gmailOperationsDefinition } from '@/levity/integrations/registry/email/providers/gmail.definition';
import { outlookOperationsDefinition } from '@/levity/integrations/registry/email/providers/outlook.definitions';
import { gwOperationsDefinition } from '@/levity/integrations/registry/logistics/gw.definitions';
import { youtubeOperationsDefinition } from '@/levity/integrations/registry/media/youtube.definition';
import { networkOperationsDefinition } from '@/levity/integrations/registry/network/network.definition';
import { Dialog } from '@/ui-library';
import { TestFlowStatus } from '@/utils/types/test-flow.types';

import { FrontAddTags } from '../components/test-flows-success-screens/front-add-tags';
import { FrontDraftReply } from '../components/test-flows-success-screens/front-draft-reply';
import { FrontUpdateConversationAssignee } from '../components/test-flows-success-screens/front-update-conversation-assignee';
import { FrontUpdateConversationCustomFields } from '../components/test-flows-success-screens/front-update-conversation-custom-fields';
import { GmailReplyToEmail } from '../components/test-flows-success-screens/gmail-reply-email';
import { ZendeskAssignTicket } from '../components/test-flows-success-screens/zendesk/zendesk-assign-ticket';
import { ZendeskCommentOnTicket } from '../components/test-flows-success-screens/zendesk/zendesk-comment-ticket';
import { ZendeskUpdateStatus } from '../components/test-flows-success-screens/zendesk/zendesk-update-status';
import { ZendeskUpdateTags } from '../components/test-flows-success-screens/zendesk/zendesk-update-tags';
import { frontOperationsDefinition } from '../levity/integrations/registry/customer-service/providers/front.definition';
import { zendeskOperationsDefinition } from '../levity/integrations/registry/customer-service/providers/zendesk.definition';

const GMAIL_URL = 'https://gmail.com';
const OUTLOOK_URL = 'https://outlook.live.com/';
const FRONT_URL = 'https://app.frontapp.com/';
const YOUTUBE_STUDIO_URL = 'https://studio.youtube.com/';
const DRIVE_URL = 'https://drive.google.com/';
const HUBSPOT_URL = 'https://app.hubspot.com/';

const DefaultSuccessComponent = () => <></>;
const ErrorTestMessage = () => <>Error</>;

export interface SuccessScreenComponentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  onCancel: () => void;
  onConfirm: (customQuery?: string) => void;
  status: TestFlowStatus;
}

type SuccessScreenComponent = React.FC<SuccessScreenComponentProps>;

// TODO: Combine the screens and the onConfirm actions into the same Record to centralize.
const successScreens: Record<string, SuccessScreenComponent> = {
  [gmailOperationsDefinition.CategorizeMessage.id]: GmailCategorizeMessage,
  [gmailOperationsDefinition.CategorizeListOfMessages.id]: GmailCategorizeMessage,
  [gmailOperationsDefinition.CreateDraft.id]: GmailCreateDraft,
  [gmailOperationsDefinition.ReplyToEmail.id]: GmailReplyToEmail,
  [gmailOperationsDefinition.ForwardEmail.id]: GmailForwardEmail,
  [outlookOperationsDefinition.GetAllMessages.id]: OutlookGetAllMessages,
  [outlookOperationsDefinition.CategorizeListOfMessages.id]: OutlookCategorizeMessage,
  [outlookOperationsDefinition.ReplyToEmail.id]: OutlookReplyEmail,
  [outlookOperationsDefinition.MoveEmailToFolder.id]: OutlookMoveToFolder,
  [outlookOperationsDefinition.CopyEmailToFolder.id]: OutlookCopyToFolder,
  [outlookOperationsDefinition.ForwardEmail.id]: OutlookForwardEmail,
  [outlookOperationsDefinition.CreateDraft.id]: OutlookDraftReply,
  [zendeskOperationsDefinition.UpdateTicketTags.id]: ZendeskUpdateTags,
  [zendeskOperationsDefinition.UpdateTicketStatus.id]: ZendeskUpdateStatus,
  [zendeskOperationsDefinition.AssignTicket.id]: ZendeskAssignTicket,
  [zendeskOperationsDefinition.CommentOnTicket.id]: ZendeskCommentOnTicket,
  [frontOperationsDefinition.AddConversationTags.id]: FrontAddTags,
  [frontOperationsDefinition.GenerateDraftReply.id]: FrontDraftReply,
  [frontOperationsDefinition.ReplyToMessage.id]: FrontReplyToMessage,
  [frontOperationsDefinition.UpdateConversationAssignee.id]: FrontUpdateConversationAssignee,
  [frontOperationsDefinition.UpdateConversationCustomFields.id]: FrontUpdateConversationCustomFields,
  [youtubeOperationsDefinition.PostComment.id]: YoutubePostComment,
  [youtubeOperationsDefinition.DeleteComment.id]: YoutubeDeleteComment,
  [youtubeOperationsDefinition.HoldCommentForReview.id]: YoutubeHoldCommmentForReview,
  [slackOperationsDefinition.SendChannelMessage.id]: SlackSendMessage,
  [gsheetsOperationsDefinition.AddWorksheetRow.id]: GSheetsAddNewRow,
  [gsheetsOperationsDefinition.UpdateWorksheetRow.id]: GSheetsUpdateRow,
  [hubspotOperationsDefinition.UpdateTicketProperties.id]: HubspotUpdateTicketProperty,
  [networkOperationsDefinition.ExecuteHttpRequest.id]: NetworkHttpRequest,
  [developmentOperationsDefinition.LogResult.id]: LoggerBlock,
  [outlookOperationsDefinition.SendEmail.id]: OutlookSendNewEmail,
  [gwOperationsDefinition.GetCurrentStatusByParams.id]: GetCurrentStatusByParams,
  [gwOperationsDefinition.GetCurrentStatusByOrderId.id]: GetCurrentStatusByOrderId,
  default: DefaultSuccessComponent,
};

const getOnConfirmUrl = (operation = '', customQuery?: string) => {
  const links = {
    [gmailOperationsDefinition.CategorizeListOfMessages.id]: GMAIL_URL,
    [gmailOperationsDefinition.CategorizeMessage.id]: GMAIL_URL,
    [gmailOperationsDefinition.CreateDraft.id]: GMAIL_URL,
    [gmailOperationsDefinition.ReplyToEmail.id]: GMAIL_URL,
    [gmailOperationsDefinition.ForwardEmail.id]: GMAIL_URL,
    [outlookOperationsDefinition.CategorizeListOfMessages.id]: OUTLOOK_URL,
    [outlookOperationsDefinition.ReplyToEmail.id]: OUTLOOK_URL,
    [outlookOperationsDefinition.MoveEmailToFolder.id]: `${OUTLOOK_URL}mail/0/${customQuery}`,
    [outlookOperationsDefinition.CopyEmailToFolder.id]: `${OUTLOOK_URL}mail/0/${customQuery}`,
    [outlookOperationsDefinition.ForwardEmail.id]: OUTLOOK_URL,
    [frontOperationsDefinition.AddConversationTags.id]: FRONT_URL,
    [frontOperationsDefinition.UpdateConversationCustomFields.id]: FRONT_URL,
    [frontOperationsDefinition.GenerateDraftReply.id]: FRONT_URL,
    [frontOperationsDefinition.UpdateConversationAssignee.id]: FRONT_URL,
    [frontOperationsDefinition.ReplyToMessage.id]: FRONT_URL,
    [youtubeOperationsDefinition.PostComment.id]: YOUTUBE_STUDIO_URL,
    [youtubeOperationsDefinition.DeleteComment.id]: YOUTUBE_STUDIO_URL,
    [youtubeOperationsDefinition.HoldCommentForReview.id]: YOUTUBE_STUDIO_URL,
    [gsheetsOperationsDefinition.AddWorksheetRow.id]: DRIVE_URL,
    [gsheetsOperationsDefinition.UpdateWorksheetRow.id]: DRIVE_URL,
    [hubspotOperationsDefinition.UpdateTicketProperties.id]: HUBSPOT_URL,
  };
  return links[operation] || undefined;
};

interface TestFlowState {
  isFlowBeingTested: boolean;
  hasFlowFinishedTesting: {
    finished: boolean;
    testStatus: TestFlowStatus;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  testExecutionResultData: any;
  lastOperation: string | null | undefined;
}

export const useTestFlow = (copy: CopyType) => {
  const [state, setState] = useState<TestFlowState>({
    isFlowBeingTested: false,
    hasFlowFinishedTesting: {
      finished: false,
      testStatus: TestFlowStatus.IDLE,
    },
    testExecutionResultData: null,
    lastOperation: null,
  });

  useEffect(() => {
    if (!state.isFlowBeingTested) {
      setFlowFinishedTesting(false, TestFlowStatus.IDLE);
    }
  }, [state.isFlowBeingTested]);

  /**
   * Toggles the visibility of the flow testing dialog.
   *
   * @param show - A boolean value to set the `isFlowBeingTested` state.
   *               If true, it indicates that the flow is being tested and the dialog should show.
   *               If false, the dialog should be hidden.
   *
   * @example
   *
   * // To show the testing dialog:
   * showDialog(true);
   *
   * // To hide the testing dialog:
   * showDialog(false);
   */
  const showDialog = (show: boolean) => {
    setState((prevState) => ({ ...prevState, isFlowBeingTested: show }));
  };

  /**
   * Updates the state to reflect the completion status of a flow test.
   *
   * @param finished - A boolean value indicating whether the flow test has finished.
   *                   If true, the flow test has completed, otherwise it's still running.
   *
   * @param isErrored - (Optional) A boolean value indicating whether the flow test has encountered an error.
   *                    Defaults to false. If true, the flow test completed with an error.
   *                    If false, the flow test completed successfully.
   *
   * @example
   *
   * // To indicate the flow test has finished successfully:
   * setFlowFinishedTesting(true);
   *
   * // To indicate the flow test has finished with an error:
   * setFlowFinishedTesting(true, true);
   */
  const setFlowFinishedTesting = (finished: boolean, status: TestFlowStatus = TestFlowStatus.IDLE) => {
    setState((prevState) => ({
      ...prevState,
      hasFlowFinishedTesting: { finished, testStatus: status },
    }));
  };

  /**
   * Sets the result data after the execution of a flow test.
   *
   * @param data - The data received after a flow test execution. This data will
   *               be used to display in the success component.
   *
   * @example
   *
   * // To set the execution result data:
   * setExecutionResultData({ labels: ["Email", "Security"] });
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setExecutionResultData = (data: any) => {
    setState((prevState) => ({ ...prevState, testExecutionResultData: data }));
  };

  /**
   * Updates the state with the ID of the last operation performed in a flow test.
   *
   * @param operationId - The ID of the last operation. Use `null` to reset/clear the value.
   *
   * @example
   *
   * // To set the last operation ID:
   * setLastOperation("createEmail");
   *
   * // To reset/clear the last operation ID:
   * setLastOperation(null);
   */
  const setLastOperation = (operationId: string | null | undefined) => {
    setState((prevState) => ({ ...prevState, lastOperation: operationId }));
  };

  const SuccessComponent = successScreens[state.lastOperation ?? 'default'] || DefaultSuccessComponent;

  const TestResult = () => {
    let content;
    if (state.hasFlowFinishedTesting.finished) {
      if (state.hasFlowFinishedTesting.testStatus === TestFlowStatus.ERROR) {
        content = <ErrorTestMessage />;
      } else {
        content = (
          <SuccessComponent
            data={state.testExecutionResultData}
            onCancel={() => {
              showDialog(false);
            }}
            onConfirm={(customQuery?: string) =>
              // TODO: To remove this once we combine successScreens and onConfirm actions.
              // @ts-ignore
              window.open(
                getOnConfirmUrl(state.lastOperation || undefined, customQuery),
                '_blank',
                'noopener,noreferrer',
              )
            }
            status={state.hasFlowFinishedTesting.testStatus}
          />
        );
      }
    } else {
      content = (
        <>
          <h3 className='text-NeutralDarkDarkest text-base not-italic font-black mb-10'>{copy.test.start}</h3>
          <div className='flex w-full items-center justify-center mb-6'>
            <CircleLoader color='#5557F6' size={32} cssOverride={{ borderWidth: '4px' }} />
          </div>
        </>
      );
    }

    return (
      <Dialog isOpen={state.isFlowBeingTested} onClose={() => {}}>
        <div className='flex flex-col'>{content}</div>
      </Dialog>
    );
  };

  return {
    TestResult,
    showDialog,
    setFlowFinishedTesting,
    setExecutionResultData,
    setLastOperation,
    TestFlowStatus,
  };
};
